<!-- 报价完成页面 -->
<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="complete-container">
    <div class="step">
      <el-steps :active="4" align-center>
         <el-step title="基础服务填报"></el-step>
        <el-step title="配置服务内容"></el-step>
        <el-step title="约定数据标准"></el-step>
        <el-step title="确认订单"></el-step>
      </el-steps>
      
    </div>
    <div class="gap"></div>
    <div class="con">
      <div class="con-one">
        <div>
          <img src="~@/assets/images/success.svg" /><span class="con-text">该报价已生成成功</span>
        </div>
        <el-button plain class="downQuote" @click="() => { $router.push(`/quoted/quotation?id=${$route.query.id}`) }">查看报价单 </el-button>
      </div>
      <div class="con-two">
        <div class="two-name">
          <span class="tn-tit">报价编号：</span
          ><span class="quo-id tn-con">{{custInfo.qSn}}</span>
        </div>
        <div class="two-name">
          <span class="tn-tit">客户名称：</span><span class="tn-con">{{custInfo.name}}</span>
        </div>
        <div class="two-name">
          <span class="tn-tit tn-abs">店铺名称：</span>
          <ul class="tn-ul">
            <!-- v-for -->
            <li class="tn-li" v-for="(item, index) in shopInfo" :key="index">
              <span class="common-over shop-name tn-con">{{item.shopName}}</span>
              <span class="shopFrom">{{item.platFormName || '--'}}/{{item.categoryName || '--'}}</span>
              <span v-if="item.serviceType == 1" class="shop-cus">普通客服</span>
              <span v-if="item.serviceType == 2" class="shop-cus goldCus">金牌客服</span>
              <span v-if="item.serviceType == 3" class="shop-cus orange">临时客服</span>
              <span class="tn-con cusDate">{{item.startTime}} ~ {{item.endTime}}（{{item.serviceDay || 0}}天）</span>
              <span class="tn-con">{{item.subaccountNum || 0}}（{{item.serviceNum || 0}}人）</span>
              <span class="tn-con cusDate">{{item.startHour}} ~ {{item.endHour}}（{{item.workHour || 0}}小时）</span>
              <span class="tn-con">{{item.shopConsultationNum || 0}}</span>
              <span class="tn-con cusDate">{{item.dataType == 1 ? '基础版' : item.dataType == 2 ? '专业版' : '旗舰版'}}</span>
            </li>
          </ul>
        </div>
        <div class="two-name">
          <span class="tn-tit">创建人：</span><span class="tn-con">{{custInfo.nickName}}</span>
        </div>
        <div class="two-name">
          <span class="tn-tit">创建时间：</span><span class="tn-con">{{custInfo.createTime}}</span>
        </div>
        <div class="btnRet">
          <el-button plain class="downQuote ret" @click="() => { $router.push(`/quoted/base-services?id=${$route.query.id}`) }">返回修改报价详情</el-button>
        </div>
      </div>
    </div>
    <div class="sixth">
      <div>
        <span class="sett-money">配置费用：</span>
        <span class="sett-rmb">¥</span>
        <span class="sett-total">{{(price).toFixed(2)}}</span>
        <el-button type="text" class="changePrice" @click="() => { editShow() }">申请手动改价</el-button>
      </div>
      <!-- <el-button type="primary" class="conOrd">客户已确认，立即转成订单</el-button> -->
    </div>
    <el-dialog v-dialogDrag title="手动改价"  v-loading="loading" element-loading-text="数据较多，拼命加载中..." :append-to-body="true" :visible.sync="editVisible" width="560px" :close-on-click-modal="false">
      <div>
        <el-form :model="ruleFormEdit" :rules="ruleEditRules" ref="ruleFormEdit" label-width="110px" class="demo-ruleForm">
          <el-form-item label="原价：">
            <span>{{ruleFormEdit.orginalPrice && ruleFormEdit.orginalPrice.toFixed(2)}} 元</span>
          </el-form-item>
          <el-form-item label="改价类型：" prop="changeType">
            <el-radio-group v-model="ruleFormEdit.changeType" @change="(val) => { changeType(val) }">
              <el-radio :label="1">加价</el-radio>
              <el-radio :label="2">减价</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="调整金额：" prop="changePrice">
            <el-input class="common-input-YUAN" style="width:35%;margin-right:50px" v-model="ruleFormEdit.changePrice" type="number" @mousewheel.native.prevent maxLength="50" @input="(val) => {changePrice(val)}"></el-input>
            <span>改价结果：{{ruleFormEdit.price && ruleFormEdit.price.toFixed(2)}} 元</span>
          </el-form-item>
          <el-form-item label="通知人：" prop="noticeIds">
            <el-select v-if="noticeList && noticeList.length" class="common-screen-input_100" v-model="ruleFormEdit.noticeIds" placeholder="请选择通知人" multiple filterable>
              <el-option v-for="item in noticeList" :key="item.id" :label="item.nickName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="改价说明：" prop="note">
            <el-input style="margin-top: 6px" class="common-screen-input_100" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="ruleFormEdit.note"></el-input>
          </el-form-item>
          <el-form-item label="操作日志">
            <div class="log-container">
              <div v-if="priceLog && priceLog.length">
                <div
                  class="logItem"
                  v-for="(item, index) in priceLog"
                  :key="index"
                >
                  <div class="logItem-name">
                    <span class="el-icon-s-tools"> {{ item.nickName }}</span>
                    <span class="textDate">{{ item.time }}</span>
                  </div>
                  <div class="itemContent border">
                    <div class="itemRight">【价格修改前】{{ item.orginalPrice || 0 }}元</div>
                    <div class="itemRight">【价格修改后】{{ item.price || 0 }}元</div>
                    <div class="itemRight">【改价说明】{{ item.note }}</div>
                  </div>
                </div>
              </div>
              <div v-else>暂无记录</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="editVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => {commitChangePrice('ruleFormEdit')}">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { quotedSuccess, calculationPrice, changePriceLog, commitChangePrice } from "../../service/quoted.js"
import { noticePeople } from "../../service/common.js"
export default {
  name: "completePage",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      shopInfo: [],
      custInfo: {},
      price: 0,
      editVisible: false, // 手动改价
      noticeList: [],
      priceLog: [],
      ruleFormEdit: {
        orginalPrice: 0,
        changeType: 1,
        changePrice: '',
        price: 0,
        noticeIds: []
      },
      ruleEditRules: { // 校验规则
        changeType: [{ required: true, message: "请选择", trigger: "change" }],
        noticeIds: [{ required: true, message: "请选择", trigger: "change" }],
        changePrice: [{ required: true, message: "请输入金额", trigger: "change" }],
        note: [{ required: true, message: "请填写说明", trigger: "change" }],
      },
    };
  },
  mounted() {
    this.changePriceLog()
    this.noticePeople()
    this.quotedSuccess()
    this.calculationPrice(1)
  },
  methods: {
    editShow () { // 手动改价
      this.ruleFormEdit.changeType = 1
      this.ruleFormEdit.changePrice = ''
      this.ruleFormEdit.noticeIds = []
      if (this.$refs["ruleFormEdit"]) this.$refs["ruleFormEdit"].resetFields();
      this.editVisible = true
    },
    changePrice (val) { // 改价金额
      let ruleFormEdit = {...this.ruleFormEdit}
      if( ruleFormEdit.changeType ) {
        if (ruleFormEdit.changeType == 1) {
          ruleFormEdit.price = Number(ruleFormEdit.orginalPrice) + Number(val)
        } else if (ruleFormEdit.changeType == 2) {
          ruleFormEdit.price = Number(ruleFormEdit.orginalPrice) - Number(val)
        }
      }
      this.ruleFormEdit = ruleFormEdit
    },
    changeType (val) { // 改价类型
      let ruleFormEdit = {...this.ruleFormEdit}
      if( ruleFormEdit.changePrice || ruleFormEdit.changePrice == 0 ) {
        if (val == 1) {
          ruleFormEdit.price = Number(ruleFormEdit.orginalPrice) + Number(ruleFormEdit.changePrice)
        } else if (val == 2) {
          ruleFormEdit.price = Number(ruleFormEdit.orginalPrice) - Number(ruleFormEdit.changePrice)
        }
      }
      this.ruleFormEdit = ruleFormEdit
    },
    async commitChangePrice(formName) { // 发起改价
      let ruleFormEdit = {...this.ruleFormEdit}
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (ruleFormEdit && ruleFormEdit.noticeIds) {
            ruleFormEdit.noticeIds = ruleFormEdit.noticeIds.join(',')
          }
          ruleFormEdit.relationId = this.$route.query.id
          this.loading = true
          let res = await commitChangePrice(ruleFormEdit)
          this.loading = false
          if (res.code == 200) {
            this.$message.success('改价成功')
            this.calculationPrice(1)
            this.changePriceLog()
            this.editVisible = false
          }
        } else {
          return false;
        }
      });
    },
    async changePriceLog() { // 改价日志
      let { data } = await changePriceLog({id: this.$route.query.id})
      this.priceLog = data.logs
    },
    async noticePeople() { // 通知人下拉
      let { data } = await noticePeople()
      this.noticeList = data
    },
    async calculationPrice(type) { // 计算价格
      let { data } = await calculationPrice({id: this.$route.query.id, type: type})
      this.price = data.price
      this.ruleFormEdit.orginalPrice = data.price
      this.ruleFormEdit.price = data.price
    },
    async quotedSuccess() { // 报价完成-详情
      let { data } = await quotedSuccess({id: this.$route.query.id})
      this.shopInfo = data
      this.custInfo = data ? data[0] : {}
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
  padding-right: 30px;
}
/deep/.el-dialog__footer {
  padding: 20px 30px;
}
.log-container {
  height: 200px;
  width: 100%;
  border: 1px #ccc solid;
  border-radius: 4px;
  overflow-y: scroll;
  padding: 8px 20px;
  box-sizing: border-box;
  .logItem {
    padding-top: 16px;
    font-size: 13px;
  }
  .border {
    border-bottom: 1px #ccc dashed;
    padding-bottom: 10px;
  }
  .itemContent {
    color: #333;
    margin-left: 14px;
    .itemRight {
      line-height: 32px;
      white-space: pre-wrap;
    }
  }
  .el-icon-s-tools {
    color: #409eff;
  }
  .textDate {
    font-size: 13px;
    color: #999999;
    margin-left: 16px;
  }
}
.complete-container {
  .step {
    position: relative;
    box-sizing: border-box;
    margin-top: 48px;
    border-bottom: solid #f7f7f7 16px;
    padding-bottom: 40px;
    // height: 149px;
    // line-height: 149px;
  }
  .con {
    padding: 81px 0 0 145px;
    text-align: left;
    .con-one {
      display: flex;
      justify-content: space-between;
      img {
        vertical-align: middle;
      }
      .con-text {
        margin-left: 40px;
        font-size: 28px;
      }
    }
    .con-two {
      position: relative;
      padding-left: 104px;
      font-size: 20px;
      .quo-id {
        color: #1890ff;
      }
      .two-name {
        position: relative;
        margin-right: 16px;
        margin: 26px 16px 0 0;
      }
      .tn-abs{
        position: absolute;
        top: 0;
        left: 0;
      }
      .tn-ul{
        display: inline-block;
        margin-left: 106px;
      }
      .tn-li{
        display: flex;
        align-items: center;
        &:nth-of-type(n+2){
          margin-top: 16px;
        }
      }
      .btnRet {
        margin-top: 34px;
      }
      .shop-name {
        display: inline-block;
        width: 178px;
        // height: 27px;
      }
      .shopFrom {
        font-size: 16px;
        margin: 0 45px 0 12px;
        // margin-left: 12px;
      }
      .cusDate {
        margin: 0 38px 0 66px;
      }
      .tn-row {
        width: 1100px;
        display: flex;
        align-items: center;
      }
      .tn-tit {
        display: inline-block;
        margin-right: 16px;
        font-size: 18px;
        width: 90px;
      }
      .tn-con {
        font-size: 20px;
      }
      .shop-cus {
          padding: 2px 8px;
          color: #65cc1e;
          background-color: #f3ffeb;
          border: 1px solid #65cc1e;
          border-radius: 2px;
        }
        .goldCus {
          color: #1890ff !important;
          background-color: #f2f9ff;
          border: 1px solid #1890ff !important;
        }
        .orange {
          border: #F09009 solid 1px !important;
          color: #F09009 !important;
          background-color: #FFF8ED !important;
        }
      .span-inline {
        display: inline-block;
        
      }
    }
  }
  .downQuote {
    margin-right: 105px;
    // padding: 0px 94px;
    width: 128px;
    height: 40px;
    color: #1890ff;
    border-color: #1890ff;
  }
  .ret {
    width: 174px;
    height: 40px;
    margin-bottom: 125px;
  }
  .sixth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 101px;
    width: 100%;
    height: 84px;
    border-top: 1px solid #B5D1FF;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    &::after {
      content: ' ';
      height: 16px;
      background: #eee;
      position: absolute;
      width: 100%;
      display: block;
      right: 0;
      top: -17px;
    }
  }
  .sett-money {
    // margin-left: 771px;
    margin-right: 15px;
  }
  .sett-rmb {
    color: #1890ff;
    margin-right: 10px;
  }
  .sett-total {
    color: #1890ff;
    font-size: 36px;
    margin-right: 24px;
  }
  .sett-status {
    color: #999999;
    font-size: 14px;
    margin-right: 24px;
  }
  .sett-return {
    border-color: #1890ff;
    color: #1890ff;
  }
  .changePrice{
    text-decoration: underline;
    color: #7B7B7B;
    &:hover{
      color: #1890ff;
    }
  }
  .conOrd{
    float: right;
    margin-right: 101px;
  }
}
</style>
